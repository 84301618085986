import { goto } from "$app/navigation";
import { LOGIN_MISSING_META, LOGIN_SOCIAL, SIGN_UP_STEP } from "$lib/constants";
import type { User } from "@supabase/supabase-js";
import { profileApi } from "./supabase/profile.api";

/**
 * Returns true, if the user is missing some meta data.
 * @param user
 */
export async function redirectOnUserMetaDataMissing(user: User): Promise<boolean> {
    console.log("redirectOnUserMetaDataMissing");
    const profile = await profileApi.getById(user.id);
    if (!profile) return false;
    let redirectPath = '/auth/signup';
    let socialLogin = false;

    if (localStorage.getItem(LOGIN_SOCIAL)) {
        localStorage.removeItem(LOGIN_SOCIAL);
        localStorage.setItem(LOGIN_MISSING_META, 'true');
        redirectPath = redirectPath + '?signup=social';
        socialLogin = true;
    } else {
        localStorage.setItem(LOGIN_MISSING_META, 'true');
    }
    console.log("USER PROFILE: ", profile);

    if (socialLogin && !profile.username) {
        console.log("MISSING: USERNAME");
        localStorage.setItem(SIGN_UP_STEP, '0');
        goto(redirectPath);

        return true;
    } else if (!profile.known_from) {
        console.log("MISSING: KNOWN_FROM");
        localStorage.setItem(SIGN_UP_STEP, '1');
        goto(redirectPath);

        return true;
    } else if (!profile.experience_level) {
        console.log("MISSING: EXPERIENCE_LEVEL");
        localStorage.setItem(SIGN_UP_STEP, '2');
        goto(redirectPath);

        return true;
    } else if (!profile.job_title) {
        console.log("MISSING: JOB_TITLE");
        localStorage.setItem(SIGN_UP_STEP, '3');
        goto(redirectPath);

        return true;
    }

    return false;
}